import React, { useEffect, useState, useRef, useCallback } from "react";

function Tab4() {    
    return (
        <div className="row py-5">
            <div className="col-md-12 py-5 my-5 text-center">
                <h3 className="py-5 my-5"><strong>Comming soon...</strong></h3>
            </div>
        </div>
    );
}

export default Tab4;