import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
// import './assets/style.scss';
// import './assets/custom.scss';
// import './assets/animated.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { EthereumClient, w3mConnectors, } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains' // change this to mainnet
import { publicProvider } from 'wagmi/providers/public'
const chains = [mainnet] // change this to mainnet
const projectId = 'bc961f10ea4096d924486a328dba4f05'


const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient:
  configureChains(chains, [publicProvider({ projectId })]).publicClient
},[])
const ethereumClient = new EthereumClient(wagmiConfig, chains)
ReactDOM.render(
	<Provider store={store}>
<WagmiConfig config={wagmiConfig}> 
		
		<App />
		<Web3Modal projectId={projectId} ethereumClient={ethereumClient} />

		</WagmiConfig>

	</Provider>,
	document.getElementById('root'),
);
