var presale_abi = require("./ABI/PresaleFactory.json");
// var pAstro_abi = require("./ABI/pAstro.json");
var Astro_abi = require('./ABI/Astro.json')
// var Avax_abi = require("./ABI/Avax.json");
var usdc_abi = require("./ABI/USDC.json");
var profitshare_abi = require("./ABI/ProfitShare.json");
// var avaxAstro_abi = require("./ABI/avaxAstro.json");
// var joerouter_abi = require("./ABI/joerouter_abi.json");
// var dashboard_abi = require("./ABI/dashboard.json");
var presale_new_abi = require("./ABI/PresaleNew.json");

export const config = {
    
    // MAINNET START //
    chainId: 1, //!!! Mainnet 
    mainNetUrl: 'https://rpc.ankr.com/eth', //!!! Mainnet
    testNetUrl: 'https://mainnet.infura.io/v3/', //!!! Mainnet
    // MAINNET END //

    // TESTNET START //
    //chainId: 5, //!!! Goerli //Fuji testnet : 43113, mainnet : 43114.  bsctestnet : 97, Rikeby: 4
    //mainNetUrl: 'https://rpc.ankr.com/eth_goerli', //!!! Goerli
    //testNetUrl: 'https://goerli.infura.io/v3/', //!!! Goerli
    // TESTNET END //

    PresaleFactoryAddress : "0x857756DeC383D2CF942CD39Ba28ccE3550fc56bf", // Crowdsale Contract
    PresaleFactoryAbi : presale_abi,
    ProfitShareAddress: "0xfBaCd706a1dcC52D3C83BD2479b4597F42ed34AE", // MyWallet
    ProfitShareAbi: profitshare_abi,
    
    AstroAddress: '0x55d4f48565bBeC5d58F722c8004f979a15d66c99', // TokenAddress
    AstroAbi: Astro_abi,
    USDCAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7', // USDTAddress goerli -> 0xc405c1798A610D46aCF709dfDDB25c21155a6dd8
    USDCAbi: usdc_abi,    
    
    PresaleNewAddress: '0x857756DeC383D2CF942CD39Ba28ccE3550fc56bf', // Crowdsale Contract
    PresaleNewAbi: presale_new_abi,

    INFURA_ID: 'e6943dcb5b0f495eb96a1c34e0d1493e'
}

export const def_config = {
    REBASE_RATE: 0.0003944,
    DPR: 0.0191,
    APY: 1000.0337,
    SWAP_FEE: 0.053,
    AUTO_SLIPPAGE: 1,
    DAILY_CLAIM: 1,
    BUY_FEE: 0.15,
    SELL_FEE: 0.3,
    DEF_PRICE: 0.01,
    ASTRO_DIGIT: 2,
    MAX_PRESALE_AMOUNT: 13000000
}