import './App.css';
import 'animate.css';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { LocationProvider } from '@reach/router';
import { toast } from 'react-toastify';
import { connectWallet, disconnect, loadWeb3 } from './core/web3';
import { config } from "./core/config";
import 'aos/dist/aos.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { getUTCNow, isEmpty } from './components/utils';
import {  useWeb3Modal } from '@web3modal/react'
import {  setWeb3 } from './store/actions';

import Web3 from 'web3';
import store from "./store";
import {
  getClaimableBalance,
  getUserPaidUSDT,
  getStartPresaleTime,
  getEndPresaleTime,
  getTokensRates,
  buy_pToken,
  claim_pToken,
} from './core/web3';
import Swal from 'sweetalert2';
import copy from "copy-to-clipboard";

import { useAccount } from 'wagmi'

import Tab1 from './components/Dashboard/Tab1';
import Tab2 from './components/Dashboard/Tab2';
import Tab3 from './components/Dashboard/Tab3';
import Tab4 from './components/Dashboard/Tab4';


import * as selectors from './store/selectors';

import logo from './assets/img/logo/logo.svg';
import tel from './assets/img/icon/telegram-icon.svg';
import twitter from './assets/img/icon/twitter-icon.svg';
import discord from './assets/img/icon/discord-icon.svg';
import tokenomics from './assets/img/maker-empower-token-tokenomics.png';
import stage from './assets/img/maker-empower-token-stage.svg';
import utility from './assets/img/maker-empower-utility.png';
import creators from './assets/img/icon/for-creators.svg';
import gamedevs from './assets/img/icon/for-game-developers.svg';
import marketers from './assets/img/icon/for-marketers.svg';
import publishers from './assets/img/icon/for-publishers.svg';
import users from './assets/img/icon/for-users.svg';
import dist from './assets/img/mpow-distribution.png';
import total from './assets/img/icon/total.svg';
import individual from './assets/img/icon/individual.svg';
import presale from './assets/img/icon/presale.svg';
import refico from './assets/img/icon/refico.png';
import meplatform from './assets/img/meplatform.png';
import monitor from './assets/img/monitor.png';
import forbes from './assets/img/forbes.svg';
import yahoo from './assets/img/yahoo.svg';
import about from './assets/img/about.png';
import liquidity from './assets/img/liquidity.png';
import { publicProvider } from 'wagmi/providers/public'

import davor from './assets/img/icon/davor.svg';
import alexander from './assets/img/icon/aleksandar.svg';
import alex from './assets/img/icon/alex.svg';
import anna from './assets/img/icon/anna.svg';
import christine from './assets/img/icon/christine.svg';

import safe from './assets/img/icon/safe.png';
import liquidityp from './assets/img/icon/liq.png';
import whitep from './assets/img/icon/whitep.png';
import taxfree from './assets/img/icon/tax-free.png';
import supplies from './assets/img/icon/supplies.png';

import story from './assets/img/story.png';
import listen2earn from './assets/img/listen2earn.png';
import watch2earn from './assets/img/watch2earn.png';
import read2earn from './assets/img/read2earn.png';
import create2earn from './assets/img/create2earn.png';

import explainer from './assets/img/MPOW-Explainer-Video-1080p.mp4';

import { fontSize } from '@mui/system';

function calculateClaimableBalance(userWalletState, claimableBalance) {
  switch(userWalletState.toLowerCase()) {
    case '0xed9482349120a4169b60e73c86347b00467be9b4':
      return claimableBalance + 14343;
    case '0x2a5356c521e7a7e70c63f12ca07ec8cefb7142bd':
      return claimableBalance + 14343;
    case '0x61a8b76a7d8ae6612898efaeb3dc87265d310d32':
      return claimableBalance + 14343;
    case '0x0b0c7b468e10fa6e8b1a913bb6f9daefa0f34291':
      return claimableBalance + 28686;
    case '0xabcfc66e753696962eaeb12590ea5c6da65f40b0':
      return claimableBalance + 21801;
    case '0xcde3939b8ce87b02c771a4dd6643bf05ed64ef14':
      return claimableBalance + 21514;
    case '0x4ba311694944eb00730ab7a10f4afbacbfb917b2':
      return claimableBalance + 12048;
    case '0xf4ac954833711c455481428f08bcd7df710115de':
      return claimableBalance + 22455;
    case '0x8967ff8eebd185f189797af4888bf719889f5970':
      return claimableBalance + 30250;
    case '0xf5ed7ebef866af164db2dbd34420e421b4640622':
      return claimableBalance + 5023;
    case '0x91dffc12a7315329594d04a707f803f22cbf4ace':
      return claimableBalance + 22471;
    case '0x4a08c5c0dd43a4900c1f4f0a6fdfd1028bdec7a8':
      return claimableBalance + 53461;
    case '0xfbacd706a1dcc52d3c83bd2479b4597f42ed34ae':
      return claimableBalance + 1251;
    case '0x1347c821f23c4f5965bce9486ad9dc1d33634703':
      return claimableBalance + 35038;
    case '0xf8a3dd5023e34a2d2fc65adcff49cb56680723be':
      return claimableBalance + 25027;
    case '0xae27e97c43fe2f88f7f3a3b7029993b7fa2714cb':
      return claimableBalance + 74623;
    case '0xeeeebb42cac96ed7ae8553074c8d1c6027ea140f':
      return claimableBalance + 501;
    case '0x6cb61019c0be0f6c1e12751bc6ee0677eeb37c1e':
      return claimableBalance + 5506;
    case '0x8e6e67830094387bef6f5f7c47141bd7f99669f8':
      return claimableBalance + 40043;
    case '0x78d08589977107df432fecf5e899e4ebe1ded313':
      return claimableBalance + 39860;
    case '0x66991739f20402ec16a09bba7616029055047957':
      return claimableBalance + 52500;
    case '0xa43d14ec25e70bcfaf607d8e856599495fe629d1':
      return claimableBalance + 4255;
    case '0x9fa3c2b6728da42544e3637a8ba757b1b6c09f8d':
      return claimableBalance + 21083;
    case '0x0c4f45d7a1dcf57dc1abe938de8c33af31c27261':
      return claimableBalance + 12013;
    case '0xc7809d7927f00f68cb907c1599f92ac89f4aa51d':
      return claimableBalance + 35000;
    case '0xa3075c21742b1afce37e7bcfa0a97c5fc249f756':
      return claimableBalance + 5005;
    case '0xed7bc9ff2f9a4fda694f18c27233a0482e898e27':
      return claimableBalance + 20022;
    case '0x707f9f718c6544d616b37479621075546b2dd9f9':
      return claimableBalance + 600975;
    case '0xe891354aee565f99f34455f5886a2dfaca9fb148':
      return claimableBalance + 20022;
    case '0x40b8b2325f8d73d065666f89e522c011ee25a977':
      return claimableBalance + 5615;
    case '0x7bf3fde4fe3d031caf369bfd15855b31b4512a9d':
      return claimableBalance + 25000;
    case '0xfaccec9b812f94549c06b39fb2d599b0a53ef2b1':
      return claimableBalance + 5911;
    case '0x3259e10b1718490751660224e21f6c2bdfb4044a':
      return claimableBalance + 1773;
    case '0x918f775a147b863a77e4b7dcc94c7aefe0b6bb4e':
      return claimableBalance + 2293;
    case '0x8448ac851247bc7ee4b222730eceaede940db946':
      return claimableBalance + 4259;
    case '0x4236cf417148a5707f007ffad438fec06761c600':
      return claimableBalance + 1638;
    case '0x35200f0e12951a071996d6a7f66586346917e148':
      return claimableBalance + 1000;
    case '0x7c3fe226390587ac0b347d2dc57043863e2602b0':
      return claimableBalance + 29482;
    case '0xc0cc7df3b04698a8108e557a71cc76951be45a2d':
      return claimableBalance + 20474;
    case '0x1efbc935f37cef5aca03585abd7c1f48e1ac1fc0':
      return claimableBalance + 3276;
    case '0x7d3decfbac8e5fe75aefae162c10963fcc93dd6c':
      return claimableBalance + 2129;
    case '0xf74fbd88b645121f7cd420b1a1ffc9a33f546362':
      return claimableBalance + 10039;
    default:
      return claimableBalance;
  }
}

function App() {
  ReactGA.initialize('G-T2DV6BB2LE');
  const userWalletState = useSelector(selectors.userWallet);
  const web3 = useSelector(selectors.web3State);
  const chainId = useSelector(selectors.authChainID);
  const { open, close } = useWeb3Modal()
  

  const inputArea = useRef(null);
  const refferUrl = "https://www.makerempower.com/?ref=";
  const copyToClipboard = () => {
    copy(inputArea.current.value);
  }

  const Ref = useRef(null);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [presaleUSDTAmount, setPresaleUSDTAmount] = useState(0);
  const [presaleETHAmount, setPresaleETHAmount] = useState(0);
  const [claimableBalance, setClaimableBalance] = useState(0);

  const updatedClaimableBalance = calculateClaimableBalance(userWalletState, claimableBalance);

  const [flag, setFlag] = useState(false);
  const [isfinished, setIsfinished] = useState(false);

  const [tokensPerEth, setTokensPerEth] = useState(14292);
  const [tokensPerUsdt, setTokensPerUsdt] = useState(76.9);

  const [start_time, setStartTime] = useState(1701928901);
  const [end_time, setEndtTime] = useState(1714165200);
  const {connector} = useAccount()

  const balance = useSelector(selectors.userBalance);


  useEffect(()=>{
    if (typeof connector == "undefined") {

       let web3 = new Web3(undefined);
    store.dispatch(setWeb3(web3));
    disconnect()
    
    }
    connector?.getProvider().then(async (provider)=>{
      console.log(provider);

      let web3 = new Web3(provider);
    store.dispatch(setWeb3(web3));
    await connectWallet();
    // disconnect()
    // if (condition) {
      
    // }
    })

  },[connector])

  const validateUsdt = (usdt) => {
    if (isEmpty(usdt) || Number(usdt) === 0) {
      toast.error("Please enter a valid amount for purchase.");
      return;
    }

    if ((Number(balance.usdtBalance) < Number(usdt))) {
      toast.error("You have insufficient amount to buy MPOW.");
      return false;
    }
    if (Number(start_time) * 1000 > getUTCNow()) {
      toast.error("Presale has not started yet.");
      return false;
    } else if (Number(end_time) * 1000 < getUTCNow()) {
      toast.error("Presale has ended.");
      setIsfinished(true);
      return false;
    }

    return true;
  }

  const validateEth = (eth) => {
    if (isEmpty(eth) || Number(eth) === 0) {
      toast.error("Please enter a valid amount for purchase.");
      return;
    }

    if ((Number(balance.avaxBalance) < Number(eth))) {
      toast.error("You have insufficient amount to buy MPOW.");
      return false;
    }
    if (Number(start_time) * 1000 > getUTCNow()) {
      toast.error("Presale has not started yet.");
      return false;
    } else if (Number(end_time) * 1000 < getUTCNow()) {
      toast.error("Presale has ended.");
      setIsfinished(true);
      return false;
    }

    return true;
  }

  const handleBuyWithUsdt = async (usdt) => {
    if (!validateUsdt(usdt)) return;
    const urlParams = new URLSearchParams(window.location.search);
    let ref = urlParams.get('ref');

    try {
      const result = await buy_pToken(0, ref, usdt * 10 ** 6);
      if (result.success) {
        // getInitAmount();
        Swal.fire({
          icon: 'success',
          title: ' Success',
          text: 'You have bought MPOW for presale successfully.'
        });

        setFlag(false);
      } else {
        toast.error("Transaction has been failed. " + result.error);
      }
    } catch (error) {
      toast.error("Transaction has been failed. " + error);
    }
  }

  const handleBuyWithEth = async (eth) => {
    if (!validateEth(eth)) return;
    const urlParams = new URLSearchParams(window.location.search);
    let ref = urlParams.get('ref');
    try {
      const result = await buy_pToken(1, ref, eth);
      if (result.success) {
        // getInitAmount();
        Swal.fire({
          icon: 'success',
          title: ' Success',
          text: 'You have bought MPOW for presale successfully.'
        });

        setFlag(false);
      } else {
        toast.error("Transaction has been failed. " + result.error);
      }
    } catch (error) {
      toast.error("Transaction has been failed. " + error);
    }
  }

  const handleClaim = async () => {
    try {
      const result = await claim_pToken();
      if (result.success) {
        // getInitAmount();
        Swal.fire({
          icon: 'success',
          title: ' Success',
          text: 'You have claimed MPOW successfully.'
        });

        setFlag(false);
      } else {
        toast.error("Transaction has been failed. " + result.error);
      }
    } catch (error) {
      toast.error("Transaction has been failed. " + error);
    }
  }

  const onConnect = async () => {
   await open()

    // await connectWallet();
  }

  const onDisconnect = async () => {
    // await disconnect();
    await open()
  }

  useEffect(() => {
    const getInitAmount = (async () => {
      // let result = await getStartPresaleTime();
      // if (result.success) {
      //     setStartTime(Number(result.start_time));
      // } else {
      //     return;
      // }

      // result = await getEndPresaleTime();
      // if (result.success) {
      //     setEndtTime(Number(result.end_time));
      // } else {
      //     return;
      // }

      let result = await getUserPaidUSDT();
      if (result.success) {
        setPresaleUSDTAmount(result.paidUSDT);
        setPresaleETHAmount(result.paidETH);
      } else {
        return;
      }

      result = await getClaimableBalance();
      if (result.success) {
        setClaimableBalance(result.claimable);
      } else {
        return;
      }

      result = await getTokensRates();
      if (result.success) {
        setTokensPerEth(result.pTokensPerETH);
        setTokensPerUsdt(result.pTokensPerUSDT);
      } else {
        return;
      }

      setFlag(true);
    });

    const setConfig = () => {
      let duration = (end_time - start_time) * 1000;
      let diff_time = end_time * 1000 - getUTCNow();
      console.log(diff_time);
      let day_split = Math.floor(diff_time / 1000 / 60 / 60 / 24);

      if (day_split > 99)
        setDay(99);
      else
        setDay(day_split);

      let hour_split = Math.floor((diff_time / 1000 / 60 / 60) % 24);
      setHour(hour_split);

      let min_split = Math.floor((diff_time / 1000 / 60) % 60);
      setMinute(min_split);

      let second_split = Math.floor((diff_time / 1000) % 60);
      setSecond(second_split);

      const elem2 = document.getElementById('progressbar_2');
      const elem3 = document.getElementById('progressbar_3');

      let temp = (100 - ((diff_time / duration) * 100)) + '%';
      elem2.style.width = temp;
      elem3.style.width = temp;
    }

    const clearTimer = () => {
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        if (!flag)
          getInitAmount();
        setConfig();
      }, 1000)
      Ref.current = id;
    }
    clearTimer();
  }, [end_time, flag, start_time]);
  
  
  return (

    <LocationProvider>
      <div>
        
        <div className='container'>
          <div className='row pt-2'>
            <div className='col-md-3'>
              <a className="navbar-brand" href="/">
                <img src={logo} className="logo-img" alt="logo" />
              </a>
            </div>

            <div className='col-md-9'>
              <nav className='navbar navbar-expand-lg float-right'>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav">
                    <li className="nav-item menu-item mr-4 text-center">
                      <a className="nav-link" href="https://makerempower.com/mpow-whitepaper.pdf" target="_blank">Whitepaper</a>
                    </li>
                    <li className="nav-item menu-item mr-4 text-center">
                      <a className="nav-link" href="#presale">$MPOW Token</a>
                    </li>
                    <li className="nav-item menu-item mr-4 text-center">
                      <a className="nav-link" href="#platform">ME Platform</a>
                    </li>
                    <li className="nav-item menu-item mr-4 text-center">
                      <a className="nav-link" href="#tokenomics">Tokenomics</a>
                    </li>
                    <li className="nav-item menu-item mr-4 text-center">
                      <a className="nav-link" href="#team">Team</a>
                    </li>
                    <li className="nav-item menu-item mr-4 text-center">
                      <a className="nav-link" href="#team">FAQ</a>
                    </li>
                    <li className="nav-item menu-item mr-4">
                      <a className="nav-link" href="https://t.me/makerempower" target="_blank">
                        <img src={tel} alt="tel-img" className='mx-auto'></img>
                      </a>
                    </li>
                    <li className="nav-item menu-item mr-4">
                      <a className="nav-link" href="https://twitter.com/MakerEmpower" target="_blank">
                        <img src={twitter} alt="twitter-img" className='mx-auto'></img>
                      </a>
                    </li>
                    <li className="nav-item menu-item mr-4">
                      <a className="nav-link" href="https://discord.gg/7XbRGED7Sa" target="_blank">
                        <img src={discord} alt="discord-img" className='mx-auto'></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className='row pt-1 pb-1'></div>
        <div className='wolf'></div>
        <div className='container' id='hero'>
          <div className='row newpromo'>
            <div className='col-md-4 vko'></div>
            <div className='col-md-4'>
              <div className='txt positive'>
                <h1 className='animate__animated animate__bounceInRight'><strong>MOTIKA.IO</strong> is live! <br />First APP based on 
MPOW Token</h1>
                <h2 className='animate__animated animate__bounceInRight animate__delay-1s'>Enjoy entertaining <br />VIDEOS & THREADS</h2>
                <h2 className='animate__animated animate__bounceInRight animate__delay-2s'>Earn MPOW Tokens</h2>
                <h2 className='animate__animated animate__bounceInRight animate__delay-3s'>Share and BOOST <br />your EARNINGS</h2>
                <div className='animate__animated animate__bounceInLeft animate__delay-3s'>
                  <a href='https://motika.io' target='_blank' className='ctabutton'>Try Out Our App</a>
                </div>
              </div>
            </div>
            <div className='wolf mob'></div>
            <div className='col-md-4'>
              <div className='container-fluid'>
                <div className='row pt-3'>
                  {(chainId === '' || userWalletState === '' || userWalletState === 0 ? (
                    <p className='p-1 text-center text-body-wrap'>Your wallet is not connected to this site.</p>
                  ) : (
                    <>
                      <p className='p-1 text-center text-body-wrap'>Your Claimable $MPOW Token Balance is <strong>{updatedClaimableBalance}</strong></p>
                    </>
                  ))
                  }
                </div>
              </div>
          

              <div className='presale-container'>
                <div className='left-bg-color'>
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="static-text" title="BUY WITH: ">
                      <Tab1 />
                    </Tab>
                    <Tab eventKey="home" title="Home">
                      {/*<div className="row py-5"><div className="col-md-12 py-5 my-5 text-center"><h3 className="py-5 my-5"><strong>Presale starts on<br />Apr 5, 2023</strong></h3></div></div>*/}
                      <Tab2 day={day} hour={hour} minute={minute} second={second} presaleAmount={presaleUSDTAmount} start_time={start_time} end_time={end_time} tokenRates={tokensPerUsdt} isfinished={isfinished} handleBuyWithUsdt={handleBuyWithUsdt} handleClaim={handleClaim} onConnect={onConnect} onDisconnect={onDisconnect} />
                    </Tab>

                    <Tab eventKey="profile" title="Home">
                      {/*<div className="row py-5"><div className="col-md-12 py-5 my-5 text-center"><h3 className="py-5 my-5"><strong>Presale starts on<br />Apr 5, 2023</strong></h3></div></div>*/}
                      <Tab3 day={day} hour={hour} minute={minute} second={second} presaleAmount={presaleETHAmount} start_time={start_time} end_time={end_time} tokenRates={tokensPerEth} isfinished={isfinished} handleBuyWithEth={handleBuyWithEth} handleClaim={handleClaim} onConnect={onConnect} onDisconnect={onDisconnect} />
                    </Tab>
                    <Tab eventKey="contact" title="Home">
                      <Tab4 />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>

          </div>
          <div className='row' id='chillandearn'>
            <div className='col-md-1'></div>
            <div className='col-md-10'>
              <section id="meplatform">
                <div className='txt'>
                  <h1 className='animate__animated animate__bounceInRight'><strong>The Maker Empowering Era is coming</strong></h1>
                  <h2 className='animate__animated animate__bounceInRight animate__delay-1s'>Transformation of the digital media has started. 
                    The Internet will never be the same again.</h2>
                  <p className='animate__animated animate__bounceInRight animate__delay-2s'>All-in-one <strong>Chill2Earn</strong> Ecosystem that empowers content creators, rewards users and transforms digital media. <br />Users will earn MPOW tokens while enjoing their favorite online content.</p>
                  <div className='animate__animated animate__bounceInLeft animate__delay-2s'>
                    <a href='https://makerempower.com/mpow-whitepaper.pdf' target='_blank' className='ctabutton'>WHITEPAPER</a><a href='#' type='button' className='ctabutton' data-bs-toggle="modal" data-bs-target="#videomodal"><i class="fa-solid fa-play"></i> Explainer Video</a>
                  </div>
                  <div class="modal fade" id="videomodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h2 class="modal-title" id="exampleModalToggleLabel">ME Platform Explainer Video</h2>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <iframe id='yt' width="860" height="550" src="https://www.youtube.com/embed/mwlmP8_QSY0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            </div>
            <div className='col-md-1'></div>
          </div>
        </div>
        <section id='story'>
          <div className='container'><img src={story} /></div>
        </section>
        <section id='story-desc'>
          <div className='container'>
            <div className='row gx-5'>
              <div className='col-lg-3'>
                <div className='textbox'>
                  <img src={create2earn} />
                  <h2>Create2Earn</h2>
                  <ul>
                    <li>Upload your content to the ME Platform.</li>
                    <li>You can upload any type of content, including videos, audio, photos, text, or even develop a small game.</li>
                    <li>Earn MPOW tokens from ads that are shown alongside your content on various ME Platform-powered apps or websites.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='textbox'>
                  <img src={watch2earn} />
                  <h2>Watch2Earn</h2>
                  <ul>
                    <li>Download the streaming app powered by the ME Platform.</li>
                    <li>The Service is 100% free.</li>
                    <li>Watch your favorite videos, movies, and shows while earning MPOW tokens from ads that are displayed in between.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='textbox'>
                  <img src={listen2earn} />
                  <h2>Listen2Earn</h2>
                  <ul>
                    <li>Download the audio app powered by the ME Platform.</li>
                    <li>The Service is 100% free.</li>
                    <li>Listen to your favorite podcasts or songs and earn MPOW tokens from ads that are displayed in between.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='textbox'>
                  <img src={read2earn} />
                  <h2>Read2Earn</h2>
                  <ul>
                    <li>Visit any website powered by the ME Platform.</li>
                    <li>Read your favorite articles or blog posts and earn one-third of the MPOW tokens earned from ads that you see.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='container' id='presale'>
          <h2>Maker Empower Token $MPOW Features</h2>
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>

            </div>
            <div className='col-md-4'></div>
          </div>
          <div className='row'>
            <div className='col-md-4 text-right'>
              <section id="liquidity">
                <div className='row'>
                  <div className='col-3'>
                    <img src={liquidityp} />
                  </div>
                  <div className='col-9'>
                    <h3 className='align-middle'>Never Ending Liquidity</h3>
                    <p>Innovative LiquidityFirst Ecosystem</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3'>
                    <img src={safe} />
                  </div>
                  <div className='col-9'>
                    <h3>Audit</h3>
                    <p>$MPOW uses OpenZeppelin's secure smart contract. <a href='https://github.com/OpenZeppelin/openzeppelin-contracts/raw/master/audits/2018-10.pdf' target='_blank'>Click here</a> to download the document.</p>
                  </div>
                </div>
              </section>
            </div>
            <div className='col-md-4'>
              <section id="liquidity">
                <div className='row'>
                  <div className='col-3'>
                    <img src={whitep} />
                  </div>
                  <div className='col-9'>
                    <h3>Whitepaper</h3>
                    <p><a href='/mpow-whitepaper.pdf' target='_blank'>Click here</a> to download the document.</p>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-3'>
                    <img src={taxfree} />
                  </div>
                  <div className='col-9'>
                    <h3>Taxless Utility Token</h3>
                    <p>$MPOW is the native utility token of the ME Platform</p>
                  </div>

                </div>

              </section>
            </div>
            <div className='col-md-4'>
              <section id="liquidity">
                <div className='row'>
                  <div className='col-3'>
                    <img src={supplies} />
                  </div>
                  <div className='col-9'>
                    <h3>Limited Supply</h3>
                    <p>75% Locked</p>
                  </div>

                </div>
                <div className='row'>
                  <div className='col-3'>
                    <img src={safe} />
                  </div>
                  <div className='col-9 sitelock'>
                    <a href="https://www.sitelock.com/verify.php?site=makerempower.com" target='_blank' ><img class="img-fluid" alt="SiteLock" title="SiteLock" src="https://shield.sitelock.com/shield/makerempower.com" /></a>
                  </div>

                </div>

              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <section id='platform'>
            <div className='row'>
              <div className='col-lg-6'>
                <img src={liquidity} />
              </div>
              <div className='col-lg-6'>
                <h2>Introducing LiquidityFirst Ecosystem</h2>
                <p>75% of all $MPOW tokens are locked and are unlocked only when a new investor comes to the platform. Investor's money is going to the liquidity pool.</p>
                <a href='#' type='button' className='ctabutton' data-bs-toggle="modal" data-bs-target="#videomodal"><i class="fa-solid fa-play"></i> Play Explainer Video</a>
              </div>
            </div>
          </section>
        </div>

        <div className='container'>
          <section id='platform'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2>ME Platform Solutions</h2>
                <p>Maker Empower Platform offers solutions for publishers, game developers, video creators, bloggers,
                  podcasters, marketing professionals and users.</p>
                <div className='row'>
                  <div className='col-md-1'><img src={creators} /></div><div className='col-md-5'><h4>FOR CONTENT CREATORS</h4>
                    <ul className="fa-ul"><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Content distribution network</li><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Content monetization solution</li></ul></div>

                  <div className='col-md-1'><img src={publishers} /></div><div className='col-md-5'><h4>FOR PUBLISHERS</h4>
                    <ul className='fa-ul'><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Embeddable Web3 cross-platform games</li><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Embeddable engaging digital media content</li></ul></div>

                  <div className='col-md-1'><img src={gamedevs} /></div><div className='col-md-5'><h4>FOR GAME DEVELOPERS</h4>
                    <ul className="fa-ul"><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Game distribution network</li><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Game monetization solution</li></ul></div>

                  <div className='col-md-1'><img src={marketers} /></div><div className='col-md-5'><h4>FOR MARKETING SPECIALISTS</h4>
                    <ul className="fa-ul"><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>White label Web3 Gaming Platform</li><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>White label Media Website</li></ul></div>
                  <div className='col-md-1'><img src={users} /></div><div className='col-md-5'><h4>FOR ADVERTISERS</h4>
                    <ul className="fa-ul"><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>AI Powered AD Platform with most advanced targeting</li></ul></div>
                  <div className='col-md-1'><img src={users} /></div><div className='col-md-5'><h4>FOR USERS</h4>
                    <ul className="fa-ul"><li><span className="fa-li"><i className="fa-solid fa-check"></i></span>Have Fun and Earn</li></ul></div>

                </div>
              </div>
              <div className='col-lg-6'>
                <img src={about} />
              </div>
            </div>
          </section>
          <section id='tokenomics'>
            <div className='row'>
              <div className='col-md-6'>
                <img src={dist} className='tokenomicsimg' />
              </div>
              <div className='col-md-6'>
                <h2>Tokenomics</h2>
                <h4>How Many MPOW Tokens Are There in Circulation?</h4>
                <p>
                  MPOW Token will sold 9% of tokens in ICO phase. 11% of tokens will be used for liquidity, 5% as rewards for early adopters, while remaining 75% of tokens are locked.
                </p>
              </div>
            </div>
            <div className='row supply'>
              <div className='col-md-4'><img src={total} /><h6>TOTAL SUPPLY<br /><strong>10.000.000.000</strong></h6></div>
              <div className='col-md-4'><img src={individual} /><h6>INDIVIDUAL PURCHASE CAP<br /><strong>50.000 USD</strong></h6></div>
              <div className='col-md-4'><img src={presale} /><h6>PRESALE<br />STARTING PRICE<br /><strong>0.02</strong><br />LISTING PRICE<br /><strong>0.3</strong></h6></div>
            </div>
          </section>
          <section id='team'>
            <h2>Meet The Team</h2>
            <p>
              ME Platform is the brainchild of a team that has 15+ years of experience in online publishing and online advertising, led by Davor Iliev (CEO) and Alex Razmovski (CTO) who is an AI scientist with extensive experience in programming.
            </p>
            <div className='row'>
              <div className='col-lg-1'></div>
              <div className='col-lg-2 col-4'><img src={davor} /><h3>Davor Iliev</h3> <span>CEO</span><a href='https://www.linkedin.com/in/davoriliev/' target='_blank'>LinkedIn</a></div>
              <div className='col-lg-2 col-4'><img src={alexander} /><h3>Alex Razmovski</h3> <span>CTO</span><a href='https://www.linkedin.com/in/razmovskialeksandar/' target='_blank'>LinkedIn</a></div>
              <div className='col-lg-2 col-4'><img src={anna} /><h3>Anna S.</h3> <span>Marketing Manager</span><a href='mailto:makerempower@gmail.com'>E-mail</a></div>
              <div className='col-lg-2 col-4'><img src={alex} /><h3>Alexander I.</h3> <span>SMM</span><a href='https://www.linkedin.com/in/aleksandar-iliev-000974144/' target='_blank'>LinkedIn</a></div>
              <div className='col-lg-2 col-4'><img src={christine} /><h3>Christine G.</h3> <span>PR</span><a href='https://www.linkedin.com/in/kristinagieva/' target='_blank'>LinkedIn</a></div>
            </div>

          </section>
        </div>



        <div className='container'>
          <section id='faq'>
            <div className='row'>
              <div className='col-lg-2'></div>
              <div className='col-lg-8 cent'>
                <h2>Frequently Asked Questions</h2>
                <p>Here are answers to the most frequently asked questions. If you cannot find your answer below, please contact us via @mpowadmin</p>

                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What is ME Platform?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        Maker Empower Platform (ME Platform) is Blockchain AI Marketing Ecosystem, that unites content makers, publishers and users.
                        For more info, <a href='#' type='button' data-bs-toggle="modal" data-bs-target="#videomodal">click here</a> to watch ME Platform explainer video.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What is $MPOW Token?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        $MPOW is utility token of ME Platform. All future activities on ME Platform ecosystem will be done via $MPOW token.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Where can I buy $MPOW Token?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        Currently $MPOW is on <a href='#'>presale</a> stage and can be bought on MPOW’s website: <a href='https://makerempower.com/'>makerempower.com</a>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        How can I buy $MPOW Token?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>To buy MPOW Tokens, Follow the below steps: </p>
                        <p><strong>Step 1:</strong> Connect your Metamask or Trust Wallet with https://makerempower.com/</p>
                        <p><strong>Step 2:</strong> Enter the amount you want to purchase and click on Buy button.</p>
                        <p><strong>Step 3:</strong> Your wallet provider will ask you to confirm the transactions 1st confirmation is for the Gas Fee and 2nd confirmation will be for Token Purchase, you need to approve both.</p>
                        <p><strong>Step 4:</strong> You have successfully bought $MPOW Token's presale.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                        Where will I recieve $MPOW Token?
                      </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        $MPOW tokens can be claimed on the website once presale is finalized. You must use the same wallet that was used to buy $MPOW Presale.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSeven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseThree">
                        When will I recieve $MPOW Token?
                      </button>
                    </h2>
                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        Users can claim $MPOW tokens from https://makerempower.com/ once presale is finalized.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingEight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseThree">
                        Is There Any Vesting For $MPOW Tokens?
                      </button>
                    </h2>
                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        No, All bought tokens will be available for users to claim once presale is finalized.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingNine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseThree">
                        What are presale stages?
                      </button>
                    </h2>
                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        The Maker Empower presale will occur in multiple stages, with the price of $MPOW tokens increasing after each stage's hard cap has been reached.
                        <br />Learn More about MPOW Presale Stages: <a href='https://makerempower.com/presale.pdf' target='_blank'>https://makerempower.com/presale.pdf</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-2'></div>
            </div>
          </section>
        </div>

        {/* Footer Start */}

        <section id='footer'>
          <div className='fbg'></div>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <img src={logo}></img>
                <div>
                  Unterwinklern strasse<br />
                  9220 Velden<br />
                  Austria
                </div>
                <div>
                  Email Us: <a href='mailto:support@makerempower.com'>support@makerempower.com</a>
                </div>
              </div>
              <div className='col-lg-3'><h3>Resources</h3>
                <ul>
                  <li><a href='#'>Whitepaper</a></li>
                  <li><a type='button' data-bs-toggle="modal" data-bs-target="#videomodal">Explainer Video</a></li>
                </ul>
              </div>
              <div className='col-lg-3'><h3>Useful Links</h3>
                <ul>
                  <li>
                    <a className="nav-link" href="#presale">$MPOW Token</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#platform">ME Platform</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#tokenomics">Tokenomics</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#team">Team</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#team">FAQ</a>
                  </li>
                </ul>
              </div>
              <div className='col-lg-2 smm'><h3>Social Media</h3>
                <ul>
                  <li>
                    <a className="nav-link" href="https://t.me/makerempower" target="_blank">
                      <img src={tel} alt="tel-img" className='mx-auto'></img>
                    </a>
                  </li>
                  <li>
                    <a className="nav-link" href="https://twitter.com/MakerEmpower" target="_blank">
                      <img src={twitter} alt="twitter-img" className='mx-auto'></img>
                    </a>
                  </li>
                  <li>
                    <a className="nav-link" href="https://discord.gg/7XbRGED7Sa" target="_blank">
                      <img src={discord} alt="discord-img" className='mx-auto'></img>
                    </a>
                  </li>
                </ul></div>
            </div>
            <div className='row note'>
              <div className='col-lg-12'>Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down as well as up. Profit may be subject to capital gains or other taxes application in your jurisdiction
              </div>
              <div className='col-lg-12'>
                Copyright © 2023 - ME Platform All Rights Reserved.
              </div>
            </div>
          </div>
        </section>

        {/* Footer End */}


        <div className='row d-none'>
          <div className='col-md-4 sidebar d-none'>

            <div className='left-bg-color'>

            </div>
            <div className='right-bg-color'>
              <div className='row pb-3'>
                <div className='col-md-12'>
                  <img src={refico} className='refico' /><h4>INVITE YOUR FRIENDS<br /><strong>EARN $MPOW TOKEN</strong></h4>
                </div>
              </div>
              <div className='container-fluid mask'>
                Share your MPOW referral link with your friends and business partners and get <strong>5% commision</strong> for every successful sale of $MPOW tokens.
              </div>
              <div className='button-gold'>
                {web3 !== null && chainId !== '' && web3.utils.toHex(chainId) !== web3.utils.toHex(config.chainId) ? (
                  <button className='btn btn-bg-gray p-2' type='button' onClick={onConnect}><strong>SWITCH</strong></button>
                ) : (chainId === '' || userWalletState === '' || userWalletState === 0 ? (
                  <button className='btn btn-bg-gray p-2' type='button' onClick={onConnect}><strong>CONNECT</strong></button>
                ) : (
                  <button className='btn btn-bg-gray p-2' type='button' onClick={onDisconnect}><strong>{userWalletState && (userWalletState.slice(0, 4) + "..." + userWalletState.slice(38))}</strong></button>
                ))}
              </div>
              <div className='reflab'>This is YOUR referral link:</div>
              <div className="custom-search">
                <input ref={inputArea} type="text" readOnly className="custom-search-input" value={refferUrl + userWalletState} />
                <button className="custom-search-botton" type="button" onClick={copyToClipboard}>copy</button>
              </div>
            </div>
          </div>
          <div className='col-md-8 mt-3'>
            <section id='whatis' className='d-none'>
              <h1>What Is Maker Empower Token (MPOW)?</h1>
              <p className='txt2'><strong>MPOW is utility token of the Maker Empower Platform (ME Platform)</strong>
                <br />ME Platform is the foundation for the new generation online media.</p>
              <p><video width="100%" controls>
                <source src={explainer} type="video/mp4" />
              </video>
              </p>
              <div className='row'><div className='col-md-4'>
                <img src={utility} className="utility-img" /></div><div className='col-md-8'><h2><strong>MPOW is used for:</strong></h2>

                  <ul className="fa-ul promo">
                    <li><span className="fa-li"><i className="fa-solid fa-check-circle"></i></span>
                      Paying the Content Makers, Video creators, Game devs, Writers, Podcasters etc. for implementing ads in their content</li>
                    <li><span className="fa-li"><i className="fa-solid fa-check-circle"></i></span>
                      Paying the Users for watching ads</li>
                    <li><span className="fa-li"><i className="fa-solid fa-check-circle"></i></span>
                      Paying the Publishers for displaying ads</li>
                  </ul>

                </div></div>
            </section>

          </div>
        </div>

      </div>
      

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </LocationProvider>

    

  );
}

export default App;
